import manager from '../oidc';

const API_HOST = process.env.API_HOST as string;


type Membership = {
  membershipId: number;
  membershipType: {
      membershipTypeId: number;
      membershipTypeName: string;
  }
};
type MembershipResponse = {
  warnings: []
  errors: []
  value: {
      isMember: true;
      memberships: Membership[];
  };
}


export const getMemberships = async (): Promise<undefined | MembershipResponse> => {
  const endpoint = `${API_HOST}/api/membership`;
  const user = await manager.getUser();

  if (!user) {
    return;
  }

  const token = user.access_token;
  const resp = await fetch(endpoint, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  });

  if (resp.status === 200) {
    const result = await resp.json();
    return result;
  }

  return undefined;
}
