
type LoginElements = {
  $loginElements: HTMLElement[];
  $authenticatedElements: HTMLElement[];
  $logoutElements: HTMLElement[];
}

export const getLoginElements = (): LoginElements => {
  const $loginElements: NodeListOf<HTMLElement> = document.querySelectorAll('.login-button');
  const $authenticatedElements: NodeListOf<HTMLElement> = document.querySelectorAll('.is-authenticated');
  const $logoutElements: NodeListOf<HTMLElement> = document.querySelectorAll('.logout-button');

  return {
    $loginElements: Array.from($loginElements),
    $authenticatedElements: Array.from($authenticatedElements),
    $logoutElements: Array.from($logoutElements),
  }
}
