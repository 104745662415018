import { UserManager } from 'oidc-client-ts';
import type { UserManagerSettings } from 'oidc-client-ts';

const OIDC_HOST = process.env.OIDC_HOST as string;
const API_HOST = process.env.API_HOST as string;

const settings: UserManagerSettings = {
  authority: OIDC_HOST,
  client_id: process.env.CLIENT_ID as string,
  redirect_uri: `${window.origin}/authorized`,
  post_logout_redirect_uri: window.origin,
  automaticSilentRenew: true,
  metadata: {
    issuer: OIDC_HOST,
    authorization_endpoint: `${OIDC_HOST}/v2/oauth/authorization/oauth2/authorize`,
    userinfo_endpoint: ``,
    end_session_endpoint: `${OIDC_HOST}/v2/oauth/authorization/logout`,
    token_endpoint: `${API_HOST}/api/token`,
    scopes_supported: [
      'openid',
      'membership.read',
    ],
  },
};
const manager = new UserManager(settings);

manager.events.addAccessTokenExpired(() => {
  manager.removeUser();
  console.log("Access token expired. User removed.");
});

export default manager;
