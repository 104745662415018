import manager from './oidc';
import {
  getMemberships,
  getLoginElements,
  showMembershipContent,
  showAuthenticatedElements,
  showUnauthenticatedElements,
} from './utils';

document.addEventListener('DOMContentLoaded', async () => {
  const {
    $loginElements,
    $logoutElements,
  } = getLoginElements();

  // subscribe to authentication events
  manager.events.addUserSignedIn(() => {
    showAuthenticatedElements();
  });
  manager.events.addUserSignedOut(() => {
    showUnauthenticatedElements();
  });
  manager.events.addAccessTokenExpired(() => {
    showAuthenticatedElements();
  });

  const user = await manager.getUser();
  if (user && user.expired) {
    await manager.removeUser();
  }

  if (user && !user.expired) {
    showAuthenticatedElements();
    const result = await getMemberships();
    if (result) {
      const memberships = result.value.memberships.map((membership: any) => {
        return membership.membershipType.membershipTypeName;
      });
      showMembershipContent(memberships);
    }
  } else {
    showUnauthenticatedElements();
  }

  // listen to button interactions
  $loginElements.forEach($el => {
    $el.addEventListener('click', () => {
      manager.signinRedirect({
        // NOTE: this is a bit of a hack as GlueUp OIDC requires we pass this
        // scope on signing redirect but technically the `oidc-client-ts`
        // doesn't support modifying the scope value.
        // @ts-ignore
        scope: `openid membership.read`,
      });
    });
  })

  $logoutElements.forEach($el => {
    $el.addEventListener('click', () => {
      manager.signoutRedirect();
    });
  });
});
