import { getLoginElements } from './getLoginElements';
import { showMembershipContent } from './showMembershipContent';

export const showUnauthenticatedElements = () => {
  const {
    $loginElements,
    $authenticatedElements,
    $logoutElements,
  } = getLoginElements();

  $loginElements.forEach(($el) => $el.style.display = 'block');
  $authenticatedElements.forEach(($el) => $el.style.display = 'none');
  $logoutElements.forEach(($el) => $el.style.display = 'none');

  showMembershipContent([]);
};
