export const showMembershipContent = (memberships: string[]) => {

  // special case for showing elements for users without a membership
  if (memberships.length === 0) {
    memberships.push('none');
  }

  let classes = memberships.map((membership) => {
    const slugified = membership
      .toLowerCase()
      .replace('+', 'plus')
      .replace(/\s+/g, '-')
    ;
    return `membership-${slugified}`;
  });
  let classesSelector = classes
    .map((c: string) => `.${c}`)
    .join(' ')
  ;

  // show valid membership content
  const showSelector = classesSelector;
  let $show = Array.from(document.querySelectorAll(showSelector));
  $show.forEach(($item: HTMLElement) => {
    $item.style.display = 'block';
  });

  // hide invalid membership content
  const hideSelector = classesSelector ?
    `[class*="membership-"]:not(${classesSelector})` :
    `[class*="membership-"]`
  ;
  let $hide = Array.from(document.querySelectorAll(hideSelector));
  $hide.forEach(($item: HTMLElement) => {
    $item.style.display = 'none';
  });
}
