import { getLoginElements } from './getLoginElements';

export const showAuthenticatedElements = () => {
  const {
    $loginElements,
    $authenticatedElements,
    $logoutElements,
  } = getLoginElements();

  $loginElements.forEach(($el) => $el.style.display = 'none');
  $authenticatedElements.forEach(($el) => $el.style.display = 'block');
  $logoutElements.forEach(($el) => $el.style.display = 'block');
};
